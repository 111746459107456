<template>
  <div class="join">
    <div class="baseBox">
      <div class="joinBox">
        <router-link to="/" class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </router-link>
        <h2>회원가입</h2>
        <h3>아래 정보를 입력해 주세요.</h3>
        <div class="form">
          <div class="flex">
            <p>
              이메일(아이디)
              <span>*</span>
            </p>
            <input type="text" v-model="email" />
          </div>
          <div class="flex">
            <p>
              비밀번호
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="password" />
            </div>
          </div>
          <div class="flex">
            <p>
              비밀번호확인
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="passwordConfirm" />
            </div>
          </div>
          <div class="flex">
            <p>
              이름
              <span>*</span>
            </p>
            <input type="text" v-model="username" />
          </div>
          <div class="flex">
            <p>
              휴대전화
              <span>*</span>
            </p>
            <div class="cert">
              <input type="text" v-model="mobileNo" @keyup="removeChar" />
              <button class="point" @click="requestCertNumber">인증번호 받기</button>
            </div>
          </div>
          <div class="flex" v-show="isRequestCertNum">
            <p>
              인증번호
              <span>*</span>
            </p>
            <div class="cert">
              <input class="tel" type="tel" v-model="certNumber" @keyup="removeChar" placeholder="인증번호를 입력해 주세요." />
              <button class="pointOut" @click="verifyNumber">인증번호 확인</button>
            </div>
          </div>
          <div class="flex">
            <p>
              생년월일
              <span>*</span>
            </p>
            <div class="birth">
              <!-- <input
                type="text"
                v-model="year"
                placeholder="년"
                maxlength="4"
                @keyup="removeChar"
              />-->
              <select class="sel" v-model="birthYear">
                <option disabled selected value>년</option>
                <option v-for="(data, i) in 100" :key="i" :value="year - i">{{ year - i }}</option>
              </select>
              <select class="sel" v-model="month">
                <option disabled selected value>월</option>
                <option v-for="(data, i) in 12" :key="i" :value="i + 1">{{ i + 1 }}</option>
              </select>
              <select class="sel" v-model="day">
                <option disabled selected value>일</option>
                <option v-for="(data, i) in 31" :key="i" :value="i + 1">{{ i + 1 }}</option>
              </select>
            </div>
          </div>
          <div class="flex">
            <p>
              성별
              <span>*</span>
            </p>
            <div class="gender">
              <label class="genderRadio">
                <input type="radio" v-model="gender" value="M" />
                <span>남</span>
              </label>
              <label class="genderRadio">
                <input type="radio" v-model="gender" value="F" />
                <span>여</span>
              </label>
            </div>
          </div>
          <div class="flex">
            <p>
              결혼 여부
              <span>*</span>
            </p>
            <div class="gender">
              <label class="genderRadio">
                <input type="radio" v-model="isMarried" value="true" />
                <span>기혼</span>
              </label>
              <label class="genderRadio">
                <input type="radio" v-model="isMarried" value="false" />
                <span>미혼</span>
              </label>
            </div>
          </div>
          <div class="flex">
            <p>
              직업
              <span>*</span>
            </p>
            <select v-model="job">
              <option value disabled>선택</option>
              <option value="EMPLOYEE">직장인</option>
              <option value="SELFEMPLOYED">자영업</option>
              <option value="UNEMPLOYED">무직</option>
            </select>
          </div>
          <div class="flex">
            <p>
              주소
              <span>*</span>
            </p>
            <select v-model="address">
              <option value disabled>선택</option>
              <option :value="data.value" v-for="(data, i) in region" :key="i">{{ data.value }}</option>
            </select>
          </div>
          <div class="termsAgree">
            <input type="checkbox" v-model="personDataCollectAgreeFlag" />
            <span @click="handleTerms('UseTerms')">이용약관</span>과
            <span @click="handleTerms('TermsPrivacy')">개인정보 처리방침</span>
            내용을 확인하였고 동의합니다.
          </div>
        </div>
        <button class="point large" @click="submit">회원가입</button>
      </div>
      <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="100%">
        <component v-bind:is="currentComponent"></component>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import moment from "moment";
import { createUser, verifyMobile, createCertNumber } from "@/api/index";
import UseTerms from "@/views/terms/UseTerms.vue";
import TermsPrivacy from "@/views/terms/TermsPrivacy.vue";
import region from "@/config/region.json";
export default {
  mixins: [format],
  components: { UseTerms, TermsPrivacy },

  data() {
    return {
      moment: moment,
      email: "",
      mobileNo: "",
      username: "",
      password: "",
      passwordConfirm: "",
      personDataCollectAgreeFlag: false,
      drawer: false,
      direction: "btt",
      currentComponent: null,
      birthYear: "",
      month: "",
      day: "",
      gender: "",
      certNumber: "",
      year: "",
      region: [],
      address: "",
      isMarried: null,
      job: "",
      isVerify: false,
      isRequestCertNum: false,
    };
  },
  mounted() {
    this.setYear();
    this.region = region.region;

    // this.getAccountList();
  },
  methods: {
    setYear() {
      let today = new Date();
      this.year = today.getFullYear();
    },
    handleClose() {
      this.drawer = false;
      var container = this.$el.querySelector(".el-drawer__body");
      container.scrollTop = 0;
    },
    handleTerms(component) {
      this.drawer = true;
      this.currentComponent = component;
    },
    // getAccountList() {
    //   fetchAccountList().then((res) => {
    //     this.accountList = res.data.data;
    //   });
    // },

    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert("휴대폰번호를 입력해 주세요.");
      } else if (this.mobileNumberCheck(this.mobileNo) == false) {
        return alert("휴대폰번호 형식이 맞지 않습니다.");
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.isRequestCertNum = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          return alert("모바일을 통해서 인증번호를 확인 후 인증번호를 입력해주세요.");
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        return alert("인증 번호를 입력해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.isVerify = true;
          return alert("정상적으로 인증이 완료 되었습니다.");
        } else {
          this.isVerify = false;
          return alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("이메일 형식이 맞지 않습니다.아래와 같이 이메일 주소 전체를 입력해주세요. service@ulmani.co.kr");
      } else if (this.password == "") {
        return alert("비밀번호를 입력해 주세요.");
        // } else if (this.checkPassword(this.password) == false) {
        //   return;
      } else if (this.password != this.passwordConfirm) {
        return alert("비밀번호를 확인해주세요.");
      } else if (this.username == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.mobileNo == "") {
        return alert("휴대폰번호를 입력해 주세요.");
      } else if (this.verify == false) {
        return alert("휴대폰 인증을 해주세요.");
      }
      if (this.isVerify == false) {
        return alert("인증번호를 확인해 주세요");
      } else if (this.birthYear == "" || this.month == "" || this.day == "") {
        return alert("생년월일을 입력해 주세요.");
      } else if (this.gender == "") {
        return alert("성별을 선택해 주세요.");
      } else if (this.isMarried == null) {
        return alert("결혼 여부를 선택해 주세요.");
      } else if (this.job == "") {
        return alert("직업을 선택해 주세요.");
      } else if (this.address == "") {
        return alert("주소를 선택해 주세요.");
      } else if (this.personDataCollectAgreeFlag == false) {
        return alert("이용약관 및 개인정보 처리방침는 필수 입니다.");
      }
      this.birth = this.birthYear + "-" + this.month + "-" + this.day;

      let data = {
        email: this.email,
        password: this.password,
        username: this.username,
        mobileNo: this.mobileNo,
        gender: this.gender,
        birth: this.birth,
        address: this.address,
        job: this.job,
        isMarried: this.isMarried,
        userTerms: this.personDataCollectAgreeFlag,
        privacyPolicy: this.personDataCollectAgreeFlag,
      };
      createUser(data).then((res) => {
        if (res.data.status == 200) {
          this.$router.push("/joinDone");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
